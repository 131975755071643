





















































import AffiliateTable from '@/components/AffiliateTable.vue'
import BasePagination from '@/components/BaseComponents/BasePagination/BasePagination.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import Vue, { PropType } from 'vue'
import AffiliateService from '@/services/api/AffiliateService'
import ARROW from '@/assets/leftArrowCalendar.svg'
import { mapActions } from 'vuex'
import { IRoute, RouteKeys, ROUTES, IAffiliateTable } from '@/constants/Affiliate'

export default Vue.extend({
  name: 'ProfileAffiliateTable',
  components: {
    AffiliateTable,
    VSelect,
    BasePagination
  },
  data: () => ({
    ARROW,
    pagination: {
      pages: 1,
      perPages: 10,
      page: 1
    },
    body: undefined,
    min_withdrawal: NaN
  }),
  props: {
    routeKey: {
      type: String as PropType<RouteKeys>,
      required: true
    }
  },
  computed: {
    route (): IRoute {
      return ROUTES[this.routeKey]
    },
    table (): IAffiliateTable {
      return {
        head: this.route.head,
        body: this.body
      }
    },
    page: {
      set (value: number): void {
        this.pagination.page = value
        console.log('page', value)
        this.getData()
      },
      get (): number {
        return this.pagination.page
      }
    },
    perPages: {
      set (value: number): void {
        this.pagination.perPages = value
        console.log('perPages', value)
        this.getData()
      },
      get (): number {
        return this.pagination.perPages
      }
    }
  },
  created () {
    if (this.routeKey === 'clicks') {
      this.pagination.perPages = 20
    }
    if (this.routeKey === 'earned') {
      this.min_withdrawal = Infinity
    }
  },
  methods: {
    ...mapActions('notifications', ['addNotification']),
    getData () {
      this.body = null
      this.route.getDataAsTable({ per_page: this.perPages, page: this.page })
        .then(({ body, pagination }) => {
          this.body = body
          this.pagination.pages = pagination.total_pages
        })['catch'](() => {
          this.addNotification({
            id: Date.now(),
            body: 'Something went wrong!',
            type: 'danger'
          })
        })

      if (this.routeKey === 'earned') {
        AffiliateService.getAffiliate()
          .then((res): void => {
            this.min_withdrawal = res.data.data?.attributes?.min_withdrawal
          })
          .catch((): void => {
            this.addNotification({
              id: Date.now(),
              body: 'Something went wrong!',
              type: 'danger'
            })
          })
      }
    }
  },
  mounted () {
    this.getData()
  }
})
