var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{class:[
    {
      select: true,
      'ad-account-select': _vm.theme === 'ad-account-select',
      'landing-select': _vm.theme === 'landing-select',
      'gray-select': _vm.theme === 'gray',
      'simple-gray-select': _vm.theme === 'simple-gray',
      'select-custom-radius': !!_vm.customRadius,
      'select-custom-border': !!_vm.customBorder
    },
    { multiple: _vm.multiple },
    { error: _vm.error },
    { 'custom-max-width': _vm.customMaxWidth }
  ],style:({
    'max-width': _vm.maxWidth + 'px',
    'min-width': _vm.minWidth + 'px',
    'border-radius': _vm.customRadius ? String(_vm.customRadius.map(function (v) { return v + 'px'; }).join(' ')) : 'inherit' }),attrs:{"getOptionLabel":function (option) { return option && option[_vm.label]; },"label":_vm.label,"options":_vm.options,"placeholder":_vm.placeholder,"clearable":false,"multiple":_vm.multiple,"dropdown-should-open":_vm.dropdownShouldOpen,"loading":_vm.loading,"searchable":_vm.searchable,"filterable":_vm.filterable,"selectable":_vm.selectable,"disabled":_vm.disabled,"appendToBody":_vm.appendToBody,"calculate-position":_vm.withPopper,"closeOnSelect":_vm.closeOnSelect,"reduce":_vm.reduce},on:{"search":_vm.handleSearch,"option:selecting":function($event){return _vm.$emit('option:selecting', $event)}},scopedSlots:_vm._u([{key:"list-header",fn:function(ref){
    var search = ref.search;
    var loading = ref.loading;
    var searching = ref.searching;
return [_vm._t("list-header",null,{"search":search,"searching":searching,"loading":loading})]}},{key:"open-indicator",fn:function(ref){
    var attributes = ref.attributes;
return [_vm._t("open-indicator",function(){return [_c('img',_vm._b({attrs:{"src":require("../../../assets/chevrone-icon.svg"),"alt":""}},'img',attributes,false))]})]}},{key:"option",fn:function(option){return [_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"flex-start","max-height":"28px"}},[_vm._t("option",function(){return [_vm._v(" "+_vm._s(option[_vm.label])+" ")]},{"option":option})],2)]}},{key:"selected-option",fn:function(selected){return [_c('div',{staticStyle:{"width":"100%","height":"100%","display":"flex","align-items":"center","justify-content":"flex-start","max-height":"28px"}},[_vm._t("selected-option",function(){return [_vm._v(" "+_vm._s(selected[_vm.label][0] ? selected[_vm.label][0].toUpperCase() + selected[_vm.label].slice(1, selected[_vm.label].length) : selected[_vm.label])+" ")]},{"selected":selected})],2)]}},{key:"no-options",fn:function(ref){
    var search = ref.search;
    var searching = ref.searching;
    var loading = ref.loading;
return [_vm._t("no-options",function(){return [_vm._v(" Sorry, no matching options ")]},{"search":search,"searching":searching,"loading":loading})]}},{key:"footer",fn:function(){return [(_vm.error)?_c('span',{staticClass:"error p-2"},[_vm._v(_vm._s(_vm.error))]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }