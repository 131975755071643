


































import Vue from 'vue'
import BasePagePreloader from '../components/BasePagePreloader.vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'ModalCustom',
  props: {
    name: {
      type: String
    },
    title: {
      type: String,
      default: 'Modal Title'
    },
    width: {
      type: String,
      default: '600'
    },
    classes: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: Number,
      default: 1920
    },
    minHeight: {
      type: Number,
      default: 0
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    shiftY: {
      type: Number,
      default: 0.2
    },
    adaptive: {
      type: Boolean,
      default: false
    }
  },
  components: { BasePagePreloader },
  computed: {
    ...mapState('interests', ['modalLoading'])
  },
  methods: {
    hide (name : string) {
      (this as any).$modal.hide(name)
    }
  }
})
