<template>
  <v-select
    :class="[
      {
        select: true,
        'ad-account-select': theme === 'ad-account-select',
        'landing-select': theme === 'landing-select',
        'gray-select': theme === 'gray',
        'simple-gray-select': theme === 'simple-gray',
        'select-custom-radius': !!customRadius,
        'select-custom-border': !!customBorder
      },
      { multiple },
      { error },
      { 'custom-max-width': customMaxWidth }
    ]"
    :getOptionLabel="option => option && option[label]"
    :label="label"
    :options="options"
    :placeholder="placeholder"
    :clearable="false"
    :multiple="multiple"
    :dropdown-should-open="dropdownShouldOpen"
    :style="{
      'max-width': maxWidth + 'px',
      'min-width': minWidth + 'px',
      'border-radius': customRadius ? String(customRadius.map(v => v + 'px').join(' ')) : 'inherit' }"
    :loading="loading"
    :searchable="searchable"
    :filterable="filterable"
    :selectable="selectable"
    :disabled="disabled"
    :appendToBody="appendToBody"
    :calculate-position="withPopper"
    :closeOnSelect="closeOnSelect"
    @search="handleSearch"
    @option:selecting="$emit('option:selecting', $event)"
    v-model="selected"
    :reduce="reduce"
  >
    <template #list-header="{ search, loading, searching }">
      <slot
        name="list-header"
        :search="search"
        :searching="searching"
        :loading="loading"
      />
    </template>
    <template #open-indicator="{ attributes }">
      <slot name="open-indicator">
        <img v-bind="attributes" src="../../../assets/chevrone-icon.svg" alt="" />
      </slot>
    </template>

    <template #option="option">
      <div
        style="width: 100%;display:flex;align-items: center; justify-content: flex-start; max-height: 28px;"
      >
        <slot name="option" :option="option">
          {{ option[label] }}
        </slot>
      </div>
    </template>

    <template #selected-option="selected">
      <div
        style="width: 100%;height: 100%; display:flex;align-items: center; justify-content: flex-start; max-height: 28px"
      >
        <slot name="selected-option" :selected="selected">
          {{ selected[label][0] ? selected[label][0].toUpperCase() + selected[label].slice(1, selected[label].length) : selected[label] }}
        </slot>
      </div>
    </template>

    <template #no-options="{ search, searching, loading }">
      <slot
        name="no-options"
        :search="search"
        :searching="searching"
        :loading="loading"
      >
        Sorry, no matching options
      </slot>
    </template>
    <template #footer>
      <span v-if="error" class="error p-2">{{ error }}</span>
    </template>
  </v-select>
</template>

<script>
import Vue from 'vue'
import { createPopper } from '@popperjs/core'
export default Vue.extend({
  name: 'BaseSelect',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      default: null
    },
    minWidth: {
      type: Number
    },
    maxHeight: {
      type: Number
    },
    maxWidth: {
      type: Number
    },
    height: {
      type: Number
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'label'
    },
    error: {
      type: String
    },
    setDefaultOption: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Function
    },
    disabled: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    customMaxWidth: {
      type: Boolean,
      default: false
    },
    customRadius: {
      default: null
    },
    customBorder: {
      default: null
    },
    theme: {
      type: String,
      default: 'default'
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    popperCustomClasses: {
      type: Array,
      default: () => []
    },
    dropdownShouldOpen: {
      type: Function,
      default: undefined
    },
    dropdownMinWidth: {
      type: Number,
      default: null
    },
    reduce: {
      required: false,
      default: (v) => v
    }
  },
  computed: {
    selected: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    handleSearch (str, loading) {
      this.$emit('onSearch', { str, loading })
    },
    withPopper (dropdownList, component, { width }) {
      dropdownList.classList.add('appendToBody-select', ...this.popperCustomClasses)
      if (this.dropdownMinWidth && this.dropdownMinWidth > (width.split('').splice(0, width.length - 2).join(''))) {
        dropdownList.classList.add('custom-min-width')
      }
      dropdownList.style.width = this.dropdownMinWidth ? this.dropdownMinWidth + 'px' : width
      dropdownList.style.minWidth = this.dropdownMinWidth ? this.dropdownMinWidth + 'px' : width
      const offset = this.dropdownMinWidth
        ? [((this.dropdownMinWidth - (width.split('').splice(0, width.length - 2).join(''))) * 0.5), 0]
        : [0, 0]
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: offset
            }
          }
        ]
      })
      return () => popper.destroy()
    }
  },
  watch: {
    options: {
      immediate: true,
      handler (opts) {
        if (this.setDefaultOption && opts.length) {
          const options = this.selectable ? opts.filter(this.selectable) : opts
          this.$emit('input', options[0])
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.select {
  width: 100%;
  color: #444444;
  background: #ffffff;
  font-size: 0.9em;
  &:hover {
    ::v-deep .vs__dropdown-toggle {
      border: 1px solid #ced4da;
    }
  }
  &.error {
    ::v-deep .vs__dropdown-toggle {
      border-color: red;
    }
  }
  ::v-deep .vs__dropdown-toggle {
    height: 36px;
    transition: 0.4s;
    padding: 0;
    .vs__search {
      height: 0;
      width: 0;
      z-index: 0;
      font-size: 14px;
    }
    input {
      margin: 0;
      max-height: 100%;
      border: none;
    }
  }
  &.vs--disabled::v-deep .vs__search {
    position: absolute;
    background-color: transparent;
    top: 8px;
  }
  ::v-deep.vs__selected {
    height: 100%;
    margin: 0;
    width: 100%;
  }
  ::v-deep.vs__selected-options {
    // border: 1px solid green;
    max-width: calc(100% - 25px);
    display: flex;
    align-items: center;
  }
  ::v-deep.vs__actions {
    // border: 1px solid blue
  }
  &.multiple ::v-deep .vs__dropdown-toggle {
    height: fit-content;
    min-height: 36px;
    .vs__selected-options {
      display: flex;
      flex-flow: row;
      gap: 4px;
    }
    .vs__selected {
      // margin: 4px 0 4px 5px;
      background: #E0F4FF;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      padding: 0 10px;
      font-size: 15px;
      width: fit-content;
      .vs__deselect {
        width: 10px;
        min-width: 10px;
        max-width: 10px;
        height: 10px;
        background-image: url('../../../assets/icons/tag-close.svg');
        background-size: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        margin: 0 0 0 10px;
        svg {
          display: none;
        }
      }
    }
    input {
      width: 0;
    }
  }

  ::v-deep .vs__selected {
    // text-transform: capitalize;
    white-space: nowrap;
    // width: auto;
  }
  ::v-deep .vs__dropdown-menu {
    .vs__no-options {
      padding: 10px;
      height: 28px;
      &:hover {
        background: transparent;
      }
    }
    & > li {
      height: 36px;
      display: flex;
      align-items: center;
      &:hover {
        background: #0d6efd;
      }
    }
  }
  ::v-deep .vs__dropdown-menu:not(.appendToBody-select) {
    max-height: 250px;
    width: 100%;
    margin-top: 2px;
    & > li::first-letter {
      text-transform: capitalize;
    }
  }
  ::v-deep .vs__actions {
    padding-right: 10px;
  }
  ::v-deep b {
    font-weight: 600;
  }
}
.select.vs--open::v-deep {
  .vs__dropdown-toggle {
    border: 1px solid #9BCBFF;
    box-shadow: 0 0 4px #86B7FE;
    border-radius: 4px;
  }
  .vs__selected {
    position: unset !important;
  }
}
.select-custom-radius {
  ::v-deep .vs__dropdown-toggle {
    border-radius: inherit;
  }
}
.select-custom-radius.vs--open {
  ::v-deep.vs__dropdown-toggle {
    border-radius: inherit;
  }
}
.select-custom-border {
  ::v-deep .vs__dropdown-toggle {
    border-left: 1px solid transparent;
  }
  &:hover {
    ::v-deep .vs__dropdown-toggle {
       border-left: 1px solid transparent;
    }
  }
}

.select.custom-max-width {
  max-width: 100% !important;
}
.gray-select {
  ::v-deep .vs__dropdown-toggle {
    background: linear-gradient(180deg, #ffffff -3.37%, #f3f3f3 91.07%);
    //padding-top: 2px;
    input {
      padding-top: 5px;
      &::placeholder {
        color: #444444;
        font-size: 15px;
      }
    }
  }
  ::v-deep .vs__actions {
    border-left: 1px solid #dddddd !important;
    padding: 8px !important;
  }
  ::v-deep .vs__dropdown-menu {
    background: #fff;
  }
}
.simple-gray-select {
  ::v-deep .vs__dropdown-toggle {
    background: linear-gradient(180deg, #ffffff -3.37%, #f3f3f3 91.07%);
    padding-top: 2px
  }
}
.gray {
  ::v-deep .vs__dropdown-toggle,
  ::v-deep .vs__dropdown-menu {
    background: linear-gradient(180deg, #ffffff -3.37%, #f3f3f3 91.07%);
    border: 1px solid #dddddd;
  }
}
.landing-select {
  ::v-deep .vs__dropdown-toggle {
    height: 55px;
    border-radius: 12px!important;
    border: 1px solid #D2D6DE;
  }
  ::v-deep .vs__selected {
    height: 55px;
    margin: 0;
    padding: 0 20px;
    color: #7C7C7C;
  }
  ::v-deep {
    ul {
      border-radius: 10px;
      padding: 5px;
      margin-top: 3px;
      border: 1px solid #D2D6DE;
      li {
        height: 47px;
        display: flex;
        align-items: center;
        color: #1C2445;
      }
      li:hover {
        background: #F9F9F9;
      }
      .vs__dropdown-option--highlight {
        background: #F9F9F9;
      }
    }
  }
  &:hover {
    ::v-deep .vs__dropdown-toggle {
      border-radius: 12px;
    }
  }
}
.landing-select.vs--open {
  border-radius: 12px;
}
.ad-account-select {
  ::v-deep.vs__dropdown-toggle {
    background: #F2F3F7;
    height: 70px;
    border: 1px solid transparent;
    cursor: pointer;
    .vs__search {
      padding: 0;
    }
  }
  ::v-deep.vs__search {
    opacity: 0;
  }
  ::v-deep.vs__selected {
    position: relative;
    margin: 0;
    padding-left: 10px;
  }
  ::v-deep.vs__search {
    opacity: 0;
  }
  ::v-deep.vs__actions {
    padding-right: 15px;
  }
}
.ad-account-select.vs--disabled {
  &:hover {
    border: none;
    ::v-deep.vs__dropdown-toggle {
      border: 1px solid transparent;
    }
  }
}
small {
  font-size: 14px;
  line-height: 20px;
}

small.error {
  margin-top: 2px;
  color: red;
  position: absolute;
}
</style>
